<style lang="less" scoped>
.more-photos {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  &-first {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: 360px;
      height: 210px;
      position: relative;
      &-hover {
        width: 94.8%;
        height: 96.8%;
        position: absolute;
        left: 2.6%;
        top: 2.6%;
        background: #070911;
        opacity: 0;
      }
      &:hover {
        .more-photos-first-item-hover {
          opacity: 0.69;
          &-title {
            // width: 100%;
            width: 77.8%;
            margin-left: 11.1%;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #dee6ff;
            line-height: 20px;
            letter-spacing: 1px;
            // padding-top: 60px;
            padding-top: 40px;
          }
          &-container {
            width: 77.8%;
            font-size: 8px;
            font-weight: 400;
            color: #dee6ff;
            line-height: 12px;
            margin-top: 10px;
            margin-left: 11.1%;
            text-align: justify;
          }
        }
        .more-photos-first-item-title {
          opacity: 0;
        }
      }
      img {
        width: 100%;
        // background: #070911;
        // opacity: 0.69;
      }
      &-title {
        width: 94.8%;
        height: 40px;
        background: #070911;
        opacity: 0.7;
        font-size: 14px;
        font-weight: 600;
        color: #dee6ff;
        line-height: 40px;
        text-align: center;
        letter-spacing: 1px;
        position: absolute;
        bottom: 0px;
        left: 2.6%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>

<template>
  <div class="more-photos">
    <div class="more-photos-first">
      <div class="more-photos-first-item"
           v-for="(item, index) in photosList"
           :key=index>
        <img :src="'/api/static/'+item.cover"
             alt="">
        <div class="more-photos-first-item-title">
          {{ item.slug }}
        </div>
        <div class="more-photos-first-item-hover">
          <div class="more-photos-first-item-hover-title">
            {{ item.slug }}
          </div>
          <div class="more-photos-first-item-hover-container">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <Common-activies :style="{width: '100%'}"></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';
export default {
  name: 'more-photos',
  data () {
    return {
      photosList: []
    }
  },
  created () {
    this.$api.getArticles({
      category: 'gallery'
    }).then(res => {
      this.photosList = res.articles;
    })
  },
  mounted () {
  },
  methods: {
  },
  components: {
    CommonActivies,
    CommonFooter
  },
}
</script>
